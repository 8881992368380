.short-select {
  width: 255px;
}

.log-payload-label {
  width: 375px;
  display: flex;
}

.radio-button-div {
  width: 120px;
  display: flex;
  margin: 15px 0 10px 0;
}

.radio-button-div label {
  margin-right: 10px;
}

.radio-button-div label label {
  margin-top: 0px !important;
  margin-right: 5px !important;
}
