h1 {
  display: inline-block !important;
  margin-right: 10px !important;
  vertical-align: middle !important;
}

.pill {
  display: inline-block !important;
  margin-right: 10px !important;
  vertical-align: middle !important;
}

.tab-views__view {
  padding-top: 20px;
}
ul.float-menu__menuitems li:nth-child(even) {
  background: #f6f6f6;
}

ul.float-menu__menuitems li {
  cursor: pointer;
}

ul.float-menu__menuitems li.float-menu__menuitem span:hover {
  font-weight: bold;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
