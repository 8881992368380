.example-exit {
  opacity: 1;
}

.modal.modal-enter,
.modal.modal-exit {
  transition: opacity 0.25s ease;
  display: block;
}
.modal.modal-enter-active,
.modal.modal-enter-done {
  display: block;
  opacity: 1;
}
.modal.modal-exit-active {
  opacity: 0;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 5000ms ease-in;
}

.example-exit {
  opacity: 1;
}

.example-exit.example-exit-active {
  opacity: 0.01;
  transition: opacity 3000ms ease-in;
}
