.table__cell--header--number {
  width: 1%;
  white-space: nowrap;
  padding-right: 100px !important;
}

.table__cell--header:last-child {
  width: 1%;
  white-space: nowrap;
  padding-right: 60px !important;
}
