body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal.modal-enter-done .modal__overlay,
.modal.modal-enter-active .modal__overlay {
  backdrop-filter: blur(var(--modal-background-blur-radius)) opacity(1);
}

.modal.modal-enter-done .modal__content,
.modal.modal-enter-active .modal__content {
  opacity: 1;

  display: block;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-item label.checkbox-label {
  margin-left: 10px;
  margin-top: 10px;
}

label.control.control--radio.control--disabled + span {
  color: #b0b0b0;
}

.control--disabled > input[type='checkbox']:checked + .control__checkbox {
  background-color: rgb(161 168 174);
}

.control--disabled > input[type='checkbox'] + .control__checkbox {
  background-color: transparent;
}

.control--checkbox.control--disabled + label.checkbox-label {
  color: rgb(161 168 174);
}
.control--disabled > input[type='checkbox']:not(:checked) + .control__checkbox svg.text-white {
  background-color: rgb(161 168 174);
  color: rgb(161 168 174);
}

button[data-testid='approved-button']:disabled {
  cursor: not-allowed;
}
