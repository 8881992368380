.svelte-jsoneditor-react {
  display: flex;
  flex: 1;
}

.jse-code-mode {
  width: 100%;
}

.cm-editor {
  max-height: 565px;
}
